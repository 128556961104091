import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, ViewChild } from '@angular/core';
import { consts } from 'src/app/core/utils/const';
import { AutoCompSetngDTO } from '../../common-model';
import { SharedService } from '../../shared.service';
import { Cnvt } from 'src/app/core/utils/cnvt';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styles: [`
.search-list-box {
  padding: 0.5rem;
}

.search-list {
  padding: 0.625rem;
  border-radius: 5px;
  margin: 0;
}

.search-list:hover {
  background-color: rgba(0, 90, 235, 0.1);
  background-color: var(--primaryLight);
  color: rgb(0, 90, 235);
  color: var(--primary);
}

.li-active {
  background-color: rgba(0, 90, 235, 0.1);
  background-color: var(--primaryLight);
  color: rgb(0, 90, 235);
  color: var(--primary);
}

`]
})
export class AutoCompleteComponent implements OnChanges {
  rutApiPro = "https://api.fundsfairway.com";
  rutApiTest = "http://13.39.148.219";
  rutUse = this.rutApiPro;

  @Input() ACSetting!: AutoCompSetngDTO; @Input() isApiLoaded!: AutoCompSetngDTO;
  @Input() searchByIsin:boolean = false;
  @Output() searchValueEmit = new EventEmitter();
  @Output() emitEvent = new EventEmitter();
  @ViewChild('searchTxtBox', { static: false }) searchTxtBox!: ElementRef;
  isSearchEnable = false; pageNum = 0; isScrollApiLoaded = true; searchValue = ''; totalRecord = 0;
  searchData: any = []; curLiSrNo = 0; isScrollDown = false; isScrollUp = false;
  curCode: any = ''; curValue: any = ''; isNoRecord = false; isDynamicDataLoaded = true;
  private specialKeys: Array<string> = ['ArrowUp', 'ArrowDown', 'Enter', 'Space', 'Backspace'];//, 'MetaLeft'
  constructor(private share: SharedService, renderer: Renderer2, public http: HttpClient) {
    renderer.listen('window', 'click', (e: Event) => {
      if (this.searchTxtBox && this.searchTxtBox.nativeElement.contains(e.target)) {
        this.pageNum = 0; this.searchData = []; this.curLiSrNo = 0;
        this.getData();
      } else {
        this.isSearchEnable = false; this.pageNum = 0;
      }
    });
  }

  ngOnChanges() {
    this.isScrollApiLoaded = false;
    if (this.ACSetting.isStatic && this.isApiLoaded) {
      this.isScrollApiLoaded = true;
      if (this.ACSetting.isStaticDataLoaded) { this.getData(); }
    }
    if (this.ACSetting.isClearTextOnLoad) {
      this.searchValue = '';
    }

  }

  inputValueComparative:string = "";
  onInputChange(){
   /* console.log(this.searchValue)
    if(this.searchValue.length > 2 ){
       
      this.getData()
    }else{
      return ''
    }*/



    if (this.ACSetting.isStatic) {
      this.isNoRecord = false;
      const key = this.searchValue.split(' ');
      this.searchData.forEach((v: any) => {
        v.isActive = false;
        if (this.searchValue === '') {
          v.isSearch = true;
        } else {
          v.isSearch = Cnvt.isKeywordFound(v.value, this.searchValue);
        }
      });
      this.isNoRecord = true;
      for (const v of this.searchData) {
        if (v.isSearch) {
          v.isActive = true; this.isNoRecord = false; this.curLiSrNo = v.srNo;
          break;
        }
      }
      return;
    }
    this.pageNum = 0;
    this.searchData = [];
    this.getData(true);

    this.inputValueComparative = this.searchValue
  }

  private getData(isKeyPress = false) {
    const req = [
     this.searchValue,
      this.searchByIsin
    ];
    if (!this.ACSetting.scrollHeight) {
      this.ACSetting.scrollHeight = 180;
    }
    if (this.ACSetting.is3LetterReq && this.searchValue.length < 2) {
      this.isSearchEnable = false;
      return;
    }
    if (this.ACSetting.disableFocusAC && !isKeyPress) {
      this.isSearchEnable = false;
      return;
    }
    if (this.ACSetting.isStatic) {
      if (this.ACSetting.data) {
        this.ACSetting.data.forEach((v: any) => {
          v.isActive = false;
          if (this.searchValue === '') {
            v.isSearch = true;
          }
        });

      }
      this.searchData = this.ACSetting.data; this.isSearchEnable = true;
      if (this.searchData.length > 0) {
        this.searchData[0].isActive = true;
      }
      if (!this.isApiLoaded && this.ACSetting.data && this.ACSetting.data.length === 0) {
        this.emitEvent.next({ operation: 'noStaticData', data: this.ACSetting });
      }
      return;
    }

    if (!this.isDynamicDataLoaded) { return };
    this.isSearchEnable = true; this.isScrollApiLoaded = false; this.isDynamicDataLoaded = false;
   /* const req = {
      moduleName: this.ACSetting.moduleName,
      searchText: this.searchValue.trim(),
      operType: this.ACSetting.operType,
      pageSize: this.ACSetting.pageSize,
      pageNum: this.pageNum
    };*/

    if(this.searchValue.length < 2){

    }else{
    this.isSearchEnable = true; this.isScrollApiLoaded = false; this.isDynamicDataLoaded = false;

   
    this.http.post(`${this.rutUse}/api/common/auto_compleate/`, req).subscribe((res: any) => {
 

   // this.share.post(consts.api.common.autoComplete, req).subscribe((res: any) => {
      try {
        const tmp = res.data; 
        this.searchValueEmit.emit(this.searchValue)
        this.totalRecord = tmp.total_record;
        let i = this.curLiSrNo = this.searchData.length; //tmp.data = Cnvt.unique(tmp.data);
        tmp.forEach((v: any) => {
          this.searchData.push({
            srNo: i,
            code: v.code,
            value: v.value,
            isActive: false,
            isSearch: true
          });
          i++;
        });
        this.searchData = Cnvt.unique(this.searchData);
      } finally {
        setTimeout(() => {
          this.isScrollApiLoaded = true; this.isDynamicDataLoaded = true;
        }, 10);
      }
    });

  }
  }
  /*onKeyDown(event: KeyboardEvent) {

    const tmp = this.searchData[this.curLiSrNo];

//    this.getData(true)

    this.searchValueEmit.emit(this.searchValue)
    // Verifica si la tecla presionada es la tecla "Borrar"
    if (event.key === 'Backspace') {
      this.emitEvent.emit({ code: this.searchValue, value: 0, operation: 'click' });

     // this.searchValueEmit.emit(this.searchValue)
    }
   // this.searchValueEmit.emit(this.searchValue)

  }*/

  onPaste(event: ClipboardEvent) {
    this.searchValueEmit.emit(this.searchValue)
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: Event) {
    // Previene el menú contextual predeterminado
    event.preventDefault();
  }

 

  onClick(code: any, value: any) {
    if (this.ACSetting.isStatic) {
      this.searchData.forEach((v: any) => { v.isSearch = true; });
    }
    if (this.ACSetting.isClearAfterClick) {
      this.searchValue = '';
    } else {
      this.searchValue = value;
    }
    this.emitEvent.emit({ code: code, value: value, operation: 'click' });
  //  this.searchValueEmit.emit(this.searchValue)

  }

  onScroll() {
    if (this.ACSetting.isStatic) {
      return;
    }
    if (this.searchData.length >= this.totalRecord) {
      return;
    }
    this.pageNum = this.pageNum + 1;
    //this.getData();
  }

  onKeyUp(event: any) {
    this.searchValueEmit.emit(this.searchValue)

    if (this.specialKeys.includes(event.code)) {
      switch (event.code) {
        case 'ArrowUp':
          this.onKeyArrowUp();
          break;
        case 'ArrowDown':
          this.onKeyArrowDown();
          break;
        case 'Enter':
          this.onKeyEnter();
          break;
      }
      return;
    }
    if (this.searchValue.trim().length < 3 && this.searchValue !== '') {
      return;
    }

    if (this.ACSetting.isStatic) {
      this.isNoRecord = false;
      const key = this.searchValue.split(' ');
      this.searchData.forEach((v: any) => {
        v.isActive = false;
        if (this.searchValue === '') {
          v.isSearch = true;
        } else {
          v.isSearch = Cnvt.isKeywordFound(v.value, this.searchValue);
        }
      });
      this.isNoRecord = true;
      for (const v of this.searchData) {
        if (v.isSearch) {
          v.isActive = true; this.isNoRecord = false; this.curLiSrNo = v.srNo;
          break;
        }
      }
      return;
    }
    this.pageNum = 0;
    this.searchData = [];
    this.getData(true);

  }

  private onKeyArrowUp() {

    if (this.curLiSrNo === 0) {
      return;
    }
    setTimeout(() => {
      this.isScrollUp = true;
    }, 10);
    setTimeout(() => {
      this.isScrollUp = false;
    }, 20);
    if (this.ACSetting.isStatic) {
      this.searchData[this.curLiSrNo].isActive = false;
      this.curLiSrNo = (this.curLiSrNo - 1);
      this.searchData[this.curLiSrNo].isActive = true;
      this.searchValue = this.searchData[this.curLiSrNo].value;
      return;
    }
    this.searchData.forEach((v: any) => {
      if (v.srNo === this.curLiSrNo) {
        v.isActive = true; this.curLiSrNo = v.srNo; this.curCode = v.code; this.curValue = v.value;
      } else {
        v.isActive = false;
      }
    });
    this.curLiSrNo = this.curLiSrNo - 1;
    this.searchValueEmit.emit(this.searchValue)

  }

  private onKeyArrowDown() {
    setTimeout(() => {
      this.isScrollDown = true;
    }, 1);
    setTimeout(() => {
      this.isScrollDown = false;
    }, 2);
    if (this.ACSetting.isStatic) {
      if (this.curLiSrNo === this.searchData.length) {
        return;
      }
      this.searchData[this.curLiSrNo].isActive = false;
      this.curLiSrNo = (this.curLiSrNo + 1);
      this.searchData[this.curLiSrNo].isActive = true;
      this.searchValue = this.searchData[this.curLiSrNo].value;
      return;
    }
    if (this.curLiSrNo >= this.searchData.length) {
      return;
    }
    this.searchData.forEach((v: any) => {
      if (v.srNo === this.curLiSrNo) {
        v.isActive = true; this.curLiSrNo = v.srNo; this.curCode = v.code; this.curValue = v.value;
      } else {
        v.isActive = false;
      }
    });
    this.curLiSrNo = this.curLiSrNo + 1;
    this.searchValueEmit.emit(this.searchValue)


  }

  private onKeyEnter() {
    const tmp = this.searchData[this.curLiSrNo];
    this.isSearchEnable = false;
    if (!this.ACSetting.isClearAfterClick && tmp !== undefined) {
      this.searchValue = tmp.value;
    }
    if(tmp !== undefined){
      this.onClick(tmp.code, tmp.value);
      this.emitEvent.emit("keyEnter");
    }else{
      this.onClick(tmp.code, tmp.value);
    }


  }


}
