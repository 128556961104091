import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { ResDTO } from 'src/app/modules/shared/common-model';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { consts } from '../const';
import { LogUserActivity_DTO, SharedService } from 'src/app/modules/shared/shared.service';

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public authorize: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenTimer: any;
  private emitTheme = new Subject<any>(); public themeEmitted$ = this.emitTheme.asObservable();

  constructor(
    private router: Router, private http: HttpClient, private share: SharedService
  ) { }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  login(res: any, userName: string) {
    if (res.statusCode) {
      if (res.statusCode !== 200) {
        this.share.showWarning('Something went wrong', 'Server Error');
        return;
      }
    }
    if (!res.default_page_url) {
      this.share.showError(`Default page is not set for ${userName} user`, 'Data Issue');
      return;
    }

      
    localStorage.setItem(consts.LocalStore.token, res.token);
    localStorage.setItem(consts.LocalStore.userID, res.id.toString());
    localStorage.setItem(consts.LocalStore.userName, userName);
    localStorage.setItem(consts.LocalStore.clientName, res.user_name);
    localStorage.setItem(consts.LocalStore.amcId, res.amc_id);
    localStorage.setItem(consts.LocalStore.expiration_date, res.expiration_date);
    localStorage.setItem(consts.LocalStore.is_light_theme, res.is_light_theme);
    localStorage.setItem(consts.LocalStore.amc_name, res.amc_name);
    localStorage.setItem(consts.LocalStore.asOnDate, '2020-06-11 20:00:47.320');
    localStorage.setItem(consts.LocalStore.isTrialUser, res.isTrialUser);
    localStorage.setItem(consts.LocalStore.eagleEyeBalance, res.eagleEyeBalance);
    localStorage.setItem(consts.LocalStore.reportBalance, res.reportBalance);
    localStorage.setItem(consts.LocalStore.role, res.role);
    this.loggedIn.next(true);
    this.emitTheme.next(this.share.isLightTheme);
    this.share.currentPageId = res.default_page_id;
    this.share.defaultPage = res.default_page_url;
    const req = {
      userId: res.id,
      pageId: this.share.currentPageId,
      dataIdentifierId: 1,

    } as LogUserActivity_DTO
    this.share.logUserActivity(req);
    this.router.navigate([this.share.defaultPage]);
    

  }
  

  logout() {
    //this.router.navigate(['login']);
    //localStorage.clear()
    const req = {
      userId: this.share.user_id,
      pageId: this.share.currentPageId,
      dataIdentifierId: 2,
    } as LogUserActivity_DTO
    this.share.logUserActivity(req);
    window.location.href = '/';
    //alert(consts.api.user.logout + this.share.user_id)
    this.get(consts.api.user.logout + this.share.user_id).subscribe((logOutRes: any) => {
      localStorage.setItem("info", logOutRes)
      //   this.clearLogin();
    });
  }

  post(api: string, reqData: any): Observable<any> {
    return this.http.post<any>(api, reqData).pipe(
      catchError(data => {
        return throwError(data);
      }),
      tap((data) => {
        return data;
      })
    );
  }

  get(api: string): Observable<any> {
    return this.http.get<ResDTO>(api).pipe(
      catchError(data => {
        return throwError(data);
      }),
      tap((data) => {
        return data;
      })
    );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.setAuthTimer(expiresIn);
      this.loggedIn.next(true);
    }
  }

  private getAuthData() {
    const token = localStorage.getItem(consts.LocalStore.token);
    const expirationDate = localStorage.getItem(consts.LocalStore.expiration_date);
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate)
    };
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      console.log('Token expire');
      this.logout();
    }, duration);
  }

  confirmLogin(user_name: string, pwd: string, res: any) {
    this.loggedIn.next(true);
    localStorage.setItem(consts.LocalStore.token, res.token);
    localStorage.setItem(consts.LocalStore.userID, res.id.toString());
    this.get(consts.api.user.logout + this.share.user_id).subscribe((logOutRes: any) => {
      this.clearLogin();
      this.post(consts.api.user.auth, { user_name: user_name, pwd: pwd }).subscribe((loginRes: any) => {
        this.login(loginRes, user_name);
      });
    });
  }

  clearLogin() {
    const theme = this.share.isLightTheme;
    this.loggedIn.next(false); localStorage.clear(); clearTimeout(this.tokenTimer);
    this.share.isLightTheme = theme;
  }

  get isAuthorize() {
    return this.authorize.asObservable();
  }

  setIsAuthorize(url: string) {
    try {
      if (this.share.lstPageMst === null) {
        this.authorize.next(true);
        return;
      }
      let isValid = false, page_id = 0, arrServerUrl = [] as any; const arrUrl = url.split('/');
      for (const v of JSON.parse(this.share.lstPageMst)) {
        if (v.url === null) {
          continue;
        }
        if (v.url === url) {
          isValid = true; page_id = v.page_id;
          break;
        } else if (arrUrl.length > 2) {
          arrServerUrl = v.url.split('/');
          if (arrServerUrl[1] === arrUrl[1]) {
            isValid = true; page_id = v.page_id;
            break;
          }
        }
      }
      if (isValid) {
        {// server side authorization
          this.share.get(consts.api.common.authUserPage + this.share.user_id + '/' + page_id).subscribe((res: any) => {
            if (res.data !== 1) {
              this.authorize.next(false);
              this.router.navigate(['/page-not-found']);
            } else {
              this.authorize.next(true);
            }
          });
        }
        this.authorize.next(true);
      } else {
        this.authorize.next(false);
      }
    } catch (ex) {
      console.log(ex);
      this.authorize.next(false);
    }
  }

}
