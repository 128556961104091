import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './core/interceptor/http-request-interceptor';
import { AuthService } from './core/utils/auth/auth.service';
import { AuthenticateGuard } from './core/utils/auth/authenticate.guard';
import { AuthorizeGuard } from './core/utils/auth/authorize.guard';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { SharedModule } from './modules/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { NoRightClickDirective } from './core/directive/no-right-click.directive';
import { NoF12Directive } from './core/directive/no-f12.directive';

import { LayoutComponent } from './modules/shared/component/layout/layout.component';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NoRightClickDirective,
    NoF12Directive,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlimLoadingBarModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
    , AuthService, AuthenticateGuard, AuthorizeGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
