import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AuthService } from './core/utils/auth/auth.service';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import {
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { Theme, light, dark } from 'src/stylesheets/theme';
import { SharedService } from './modules/shared/shared.service';
import { Subscription } from 'rxjs';
import 'jspdf-autotable'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  themeType = false;
  private active: Theme = dark;
  private availableThemes: Theme[] = [light, dark];
  themeSub!: Subscription; isLogInSub!: Subscription;

  constructor(private authService: AuthService, private loader: SlimLoadingBarService,
    private renderer: Renderer2,
    private share: SharedService, private router: Router) {
    this.isLogInSub = authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res;
    });
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }

  
  generatePDF() {

    var options1 = {
       padding: 50
    };


    const doc = new jsPDF()
    autoTable(doc, { html: '#myTable' })

        doc.save('table.pdf')
  }

  ngOnInit() {
    if (/msie\s|trident\//i.test(window.navigator.userAgent)) {
      this.setLightTheme();
      setTimeout(() => {
        this.authService.autoAuthUser();
      }, 10);
    } else {
      this.checkTheme();
      setTimeout(() => {
        this.authService.autoAuthUser();
      }, 10);
      this.themeSub = this.authService.themeEmitted$.subscribe((res) => {
        if (res) {
          this.setLightTheme();
        } else {
          this.setDarkTheme();
        }
      });
    }
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loader.start();
    }
    if (event instanceof NavigationEnd) {
      this.loader.complete();
    }
    if (event instanceof NavigationCancel) {
      this.loader.stop();
    }
    if (event instanceof NavigationError) {
      this.loader.stop();
    }
  }

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name === dark.name;
  }

  setDarkTheme(): void {
    this.renderer.addClass(document.body, 'dark-theme');
    this.renderer.removeClass(document.body, 'light-theme');
    this.setActiveTheme(dark);
  }

  setLightTheme(): void {
    this.renderer.addClass(document.body, 'light-theme');
    this.renderer.removeClass(document.body, 'dark-theme');
    this.setActiveTheme(light);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  checkTheme() {
    if (!this.share.isLightTheme) {
      this.setDarkTheme();
    } else {
      this.setLightTheme();
    }
  }

  ngOnDestroy() {
    this.themeSub.unsubscribe(); this.isLogInSub.unsubscribe(); //this.navigateSub.unsubscribe();
  }
}
