import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { consts } from '../utils/const';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { AuthService } from '../utils/auth/auth.service';

@Injectable()

export class HttpRequestInterceptor implements HttpInterceptor {
  isLoggedIn = false;
  arrDownloadApi: any = [
    consts.api.mktIntel.mktXl,
    consts.api.common.table2Xl,
    consts.api.fundCompetitive.adminXl,
    consts.api.fundDashboard.adminXl,
    consts.api.amcAnalytics.adminXl,
    consts.api.competitiveAnalytics.adminXl
  ];
  arrOpenApi: any = [
    consts.api.register.checkEmailExists,
    consts.api.register.countryData,
    consts.api.register.register,
    consts.api.user.auth,
    consts.api.register.verifyUser,
    consts.api.register.registerPreApprovedUser
  ]
  constructor(private router: Router, private loader: SlimLoadingBarService,
    private share: SharedService, private authService: AuthService) { }
    token:any =""
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isNewAPIDomain = false;
    for (let domain of consts.newApiDomains) {
      if(request.url.includes(domain)) {
        isNewAPIDomain = true;
        break;
      }
    }
    if (this.arrDownloadApi.includes(request.url)) {
      request = request.clone({
        responseType: 'arraybuffer',
      });
    } else if(isNewAPIDomain) {
      request = request.clone({
        setHeaders: { Accept: 'application/json' }
      });
    } else {
      request = request.clone({
        setHeaders: { Accept: 'application/json', source: 'explorer', version: '1.0.0' }
      });
    }
    if(request.url.startsWith("https://fundsfairway.com:81")){
      this.token = localStorage.getItem(consts.LocalStore.token);
 
     }else{
        this.token = localStorage.getItem('token2')
     }
     
     if (this.token || this.arrOpenApi.includes(request.url)) {
       request = request.clone({
         setHeaders: { Authorization: 'Bearer ' + this.token }
       });
     }  else {
      console.log('intercept -> token expire');
      this.router.navigate(['']);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error); this.loader.complete();
        if (error.status !== 0) {
          if (error.status === 401) {
            this.authService.logout();
            console.log('Unauthorized');
            this.share.showError('', 'User name or password is invalid');
          } else {
            console.log(error.message);
            this.share.showError('Something seems wrong. Please try after sometime or report to us', 'Error');
          }
        } else {
          if (this.isLoginPage(error)) {
            this.share.showError('Something seems wrong. Please try after sometime or report to us', 'Error');
          } else {
            this.router.navigate(['no-internet']);
          }
        }
        return throwError(error);
      })
    );
  }

  private isLoginPage(error: any): boolean {
    let res = false;
    try {
      if (consts.api.user.auth === error.url) {
        res = true;
      } else {
        res = false;
      }
    } catch {
      res = false;
    }
    return res;
  }

  private getAPiName(url: string) {
    let res = '';
    const tmpArr = url.split('/');
    if (tmpArr.length > 0) {
      res = tmpArr[tmpArr.length - 2];
    } else {
      res = '';
    }
    return res;
  }
}
